// eslint-disable-next-line @roq/filename-suffix-mismatch
import { makeStyles } from '@mui/styles';

export const useTwoFactorRegistrationStyles = makeStyles(() => ({
  title: {
    fontWeight: '700',
    marginBottom: '0px',
    color: 'black',
    textAlign: 'left',
  },
  twoFactorRefistrationText: {
    color: '#626262',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    textAlign: 'left',
    '& p': {
      margin: '0px',
      marginTop: '8px'
    }
  },
  list: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    '& p': {
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.17px',
      wordWrap: 'break-word',
      margin: '0px'
    },
    '& li': {
     alignItems: 'flex-start'
    },
    '& .MuiListItemText-root': {
      margin: '0px'
    }
  },
}));
