import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { AuthHeadingBlock } from 'modules/auth/components';
import { useTranslation } from 'modules/common/hooks';
import { useTwoFactorAuthenticationForm } from 'modules/two-factor-authentication/components/two-factor-authentication/two-factor-authentication.styles';
import { ReactElement, useState } from 'react';

interface TwoFactorAuthenticationComponentProps {
  onSubmit: (code: string) => void;
}

export const TwoFactorAuthenticationComponent = ({ onSubmit }: TwoFactorAuthenticationComponentProps): ReactElement => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>();

  const classes = useTwoFactorAuthenticationForm();
  const authenticateWithTwoFactor = async () => {
    onSubmit(code);
  };

  return (
    <>
      <AuthHeadingBlock>
        <Typography component="h1" className={classes.title} variant="h3" gutterBottom>
          {t('two-factor.authentication.title')}
        </Typography>
        <div className={classes.twoFactorFormText}>
          <p>
            To complete enter the code from your <b>Google Authenticator App</b>{' '}
          </p>
        </div>
      </AuthHeadingBlock>
      <Divider />
      <Grid container p={'24px'} gap={'12px'}>
        <Box width={'100%'}>
        <InputLabel htmlFor="email">{t('Authentication Code')}</InputLabel>
        <TextField
          id="code"
          name="code"
          type="number"
          autoFocus={true}
          onChange={(eve) => {
            setCode(eve.target.value);
          }}
          fullWidth
          variant="filled"
          className={classes.numberInput}
        />
        </Box>
        <LoadingButton
          disabled={!code?.length}
          type="submit"
          variant="contained"
          color="primary"
          size="xlarge"
          fullWidth
          onClick={authenticateWithTwoFactor}
        >
          {t('two-factor.authentication.button-authenticate')}
        </LoadingButton>
      </Grid>
    </>
  );
};
