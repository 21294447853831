import { setCookie } from 'modules/common/utils';
import { clearCookie } from 'modules/common/utils/cookie';
import { PRE_LOGIN_EMAIL_KEY, PRE_LOGIN_EMAIL_MAX_AGE } from 'views/login/constants/pre-log-in-email-key.constant';
import { UseSetPreLoginEmailCookieInterface } from 'views/login/interfaces/use-set-pre-login-email-cookie.interface';

export const useSetPreLoginEmailCookie = (): UseSetPreLoginEmailCookieInterface => {
  const setPreLoginEmailCookie = async (email: string): Promise<void> => {
    clearCookie(PRE_LOGIN_EMAIL_KEY);
    setCookie(PRE_LOGIN_EMAIL_KEY, email, PRE_LOGIN_EMAIL_MAX_AGE);
    await new Promise((resolve) => setTimeout(resolve, 150));
  }

  return {
    setPreLoginEmailCookie
  }
}