import LoadingButton from '@mui/lab/LoadingButton';
import { Card, CardMedia, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from '@mui/material';
import { AuthHeadingBlock } from 'modules/auth/components';
import { useTranslation } from 'modules/common/hooks';
import ListCountOne from 'modules/common/icons/list-count-one-icon.svg';
import ListCountThree from 'modules/common/icons/list-count-three-icon.svg';
import ListCountTwo from 'modules/common/icons/list-count-two-icon.svg';
import { useTwoFactorRegistrationStyles } from 'modules/two-factor-authentication/components/two-factor-registration/two-factor-registration.styles';
import { ReactElement } from 'react';

interface TwoFactorRegistrationComponentPropsInterface {
  qrCodeUrl: string;
  onSubmit: () => void;
}

export const TwoFactorRegistrationComponent = ({
  qrCodeUrl,
  onSubmit,
}: TwoFactorRegistrationComponentPropsInterface): ReactElement => {
  const { t } = useTranslation();
  const classes = useTwoFactorRegistrationStyles();

  return (
    <>
      <AuthHeadingBlock>
        <Typography component="h1" className={classes.title} variant="h3" gutterBottom>You are invited to join Helm Bridge</Typography>
      <div className={classes.twoFactorRefistrationText}>
        <p>To accept the invite, we kindly ask you to authenticate with a second device</p>
        </div>
      </AuthHeadingBlock>
      <Divider />
      <Grid container p={'24px'} gap={'24px'}>
      <List className={classes.list}>
        <ListItem disablePadding>
          <ListItemIcon>
            <SvgIcon sx={{ width: 25, height: 25 }} viewBox="0 0 25 25" component={ListCountOne} />
          </ListItemIcon>
          <ListItemText
            primary={
              <p>
                Please Download the <b>Google Authenticator App</b> to your Phone
              </p>
            }
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <SvgIcon sx={{ width: 25, height: 25 }} viewBox="0 0 25 25" component={ListCountTwo} />
          </ListItemIcon>
          <ListItemText
            primary={
              <p>
                Open <b>Google Authenticator App</b> and scan the QR Code below
              </p>
            }
          />
        </ListItem>
        <Grid container justifyContent={'start'} ml={3}>
          <Card sx={{ maxWidth: 80, marginLeft: '30px' }}>
            <CardMedia component="img" image={qrCodeUrl} alt="green iguana" />
          </Card>
        </Grid>
        <ListItem disablePadding>
          <ListItemIcon>
            <SvgIcon sx={{ width: 25, height: 25 }} viewBox="0 0 25 25" component={ListCountThree} />
          </ListItemIcon>
          <ListItemText primary={<p>Enter Code (next screen)</p>} />
        </ListItem>
      </List>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        size="xlarge"
        fullWidth
        onClick={onSubmit}
      >
        {t('two-factor.registration.button-next')}
        </LoadingButton>
      </Grid>
    </>
  );
};
