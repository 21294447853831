import { gql } from '@apollo/client';
import { useAsyncOperation } from 'modules/common/hooks';
import { UseAsyncHookInterface } from 'modules/common/hooks/use-async-operation.hook';
import { requestGql } from 'modules/common/utils/request-gql';
import { useCallback } from 'react';

export interface HandlePreLogin {
  id: string;
  qrCodeUrl: string;
  isTwoFactorAuthEnabled: boolean;
  isTwoFactorAuthExpired: boolean;
  isTwoFactorAuthConfigured: boolean;
}

interface HandlePreLoginInput {
  email: string;
  password: string
}

export type UseHandlePreLogin = UseAsyncHookInterface<HandlePreLoginInput, HandlePreLogin>

export const useHandlePreLogin = (): UseHandlePreLogin => {
  const handlePreLogin = useCallback((input: HandlePreLoginInput) => requestGql<HandlePreLogin>({
    mutation: gql`mutation PreLoginCheck($input: AuthPreLoginCheckDto!){
      preLoginCheck(input: $input){
        id
        qrCodeUrl
        isTwoFactorAuthEnabled
        isTwoFactorAuthExpired
        isTwoFactorAuthConfigured
      }
    }`,
    variables: {
      input
    }
  }, null, 'preLoginCheck'), []);


  return useAsyncOperation({
    callback: handlePreLogin
  })
}
