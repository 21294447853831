import { AppDispatch } from 'configuration/redux/store';
import { loginAction } from 'modules/auth/auth.slice';
import { useResetAuthError } from 'modules/auth/hooks';
import { authSelector } from 'modules/auth/selectors';
import { useRouter } from 'modules/common/hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes';

export interface LoginError {
  message: string;
  code?: string;
}

export interface LoginFormValues {
  email: string;
  password: string;
  keepMeLoggedIn?: boolean;
  code?: string;
}

export interface UseLoginInterface {
  isLoading: boolean;
  error: LoginError;
  handleLogin: (formValues: LoginFormValues) => void;
  resetError: () => void;
  clearStorage: () => void;
}

export const useLogin = (): UseLoginInterface => {
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();

  const { isLoading, error } = useSelector(authSelector);
  const { resetAuthError: resetError } = useResetAuthError();

  const clearStorage = (): void => {
    localStorage.clear();
  }

  const handleLogin = useCallback(
    async (formValues: LoginFormValues): Promise<void> => {
      await dispatch(loginAction({ data: formValues }));
    },
    [dispatch, router],
  );

  return {
    error,
    isLoading,
    handleLogin,
    resetError,
    clearStorage
  };
};
