/* eslint-disable @roq/no-invalid-style-resource */
import { makeStyles } from '@mui/styles';

export const useTwoFactorAuthenticationForm = makeStyles(() => ({
  numberInput: {
    margin: '0px !important',
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
  title: {
    marginBottom: '0px',
    color: 'black',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '120%',
    textWrap: 'auto'
  },
  twoFactorFormText: {
    color: '#626262',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    textAlign: 'left',
    '& p': {
      margin: '0px',
      marginTop: '8px'
    }
  },
  backButton: {
    color: 'white',
    textTransform: 'none',
  },
}));
