import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, Step, StepButton, Stepper } from '@mui/material';
import { AuthLayout } from 'layouts';
import { SwitchPageBlock } from 'modules/auth/components';
import { RoqLink } from 'modules/common/components';
import { useTranslation } from 'modules/common/hooks';
import { FormAlert } from 'modules/forms/components';
import { TwoFactorAuthenticationComponent } from 'modules/two-factor-authentication/components/two-factor-authentication/two-factor-authentication.component';
import { useTwoFactorAuthenticationForm } from 'modules/two-factor-authentication/components/two-factor-authentication/two-factor-authentication.styles';
import { TwoFactorRegistrationComponent } from 'modules/two-factor-authentication/components/two-factor-registration/two-factor-registration.component';
import { ReactElement, useState } from 'react';

interface TwoFactorAuthenticationWrapperComponentPropsInterface {
  twoFactorConfiguration: {
    id?: string;
    qrCodeUrl: string;
    isTwoFactorAuthEnabled: boolean;
    isTwoFactorAuthConfigured: boolean;
    isTwoFactorAuthExpired: boolean;
  };
  loginError: {
    message: string;
  };
  onTwoFactorAuthSubmit: (code: string) => void;
  resetLoginError: () => void;
  backToLogin?: () => void;
}

export const TwoFactorAuthenticationWrapperComponent = ({
  twoFactorConfiguration,
  onTwoFactorAuthSubmit,
  loginError,
  resetLoginError,
  backToLogin,
}: TwoFactorAuthenticationWrapperComponentPropsInterface): ReactElement => {
  const { t } = useTranslation();
  const { qrCodeUrl, isTwoFactorAuthConfigured, isTwoFactorAuthExpired } = twoFactorConfiguration || {};
  const [activeStep, setActiveStep] = useState<number>(0);
  const classes = useTwoFactorAuthenticationForm();

  const steps = [
    {
      key: 0,
      label: 'Configure 2FA',
    },
    {
      key: 1,
      label: 'Verify 2FA Code',
    },
  ];

  const BackLink = () => (
    <RoqLink
      onClick={() => {
        if (!isTwoFactorAuthConfigured) {
          if (activeStep === 0) {
            backToLogin();
          } else {
            setActiveStep(0);
          }
        } else {
          backToLogin();
        }
      }}
    >
      <Button className={classes.backButton} variant="text" startIcon={<ArrowBackIosNewIcon />}>
        {activeStep === 0 ? t('go-to-login') : 'Back'}
      </Button>
    </RoqLink>
  );

  return (
    <>
      <AuthLayout noAside title={'2FA Registration'} back={<BackLink />} footer={<SwitchPageBlock registered />}>
        {!isTwoFactorAuthConfigured && (
          <>
            <Stepper style={{ display: 'none' }} activeStep={activeStep} alternativeLabel sx={{ marginBottom: 3 }}>
              {steps.map((item) => (
                <Step key={item.key}>
                  <StepButton color="inherit" onClick={() => setActiveStep(item.key)}>
                    {item.label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <Box>
              {activeStep === 0 && (
                <TwoFactorRegistrationComponent qrCodeUrl={qrCodeUrl} onSubmit={() => setActiveStep(1)} />
              )}
              {activeStep === 1 && <TwoFactorAuthenticationComponent onSubmit={onTwoFactorAuthSubmit} />}
            </Box>
          </>
        )}
        {isTwoFactorAuthConfigured && isTwoFactorAuthExpired && (
          <TwoFactorAuthenticationComponent onSubmit={onTwoFactorAuthSubmit} />
        )}
        {loginError && <FormAlert error={loginError} onClose={resetLoginError} data-cy="form-alert-login-error" />}
      </AuthLayout>
    </>
  );
};
