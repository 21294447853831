import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { ReactElement } from 'react';
import { SSOLoginFormPropsInterface } from 'views/login/interfaces/sso-login-form-props.interface';

export const SSOLoginFormComponent = (props: SSOLoginFormPropsInterface): ReactElement => {
  const { handleSSOLogin } = props;

  return (
    <Grid container rowGap={'12px'} padding={'24px'} xs={12}>
      <LoadingButton
        type="submit"
        variant="contained"
        color="secondary"
        size="xlarge"
        onClick={handleSSOLogin}
        fullWidth
        sx={{ height: '48px' }}
      >
        Continue with Single Sign-on
      </LoadingButton>
    </Grid>
  )
}