import { useEffect, useState } from 'react';
import { COOKIE_CONSENT_KEY } from 'views/login/constants';

interface UseUserCookieConsent {
  setCookieConsent: () => void;
  cookieConsentAvailable: boolean
}

export const useUserCookieConsent = (): UseUserCookieConsent => {
  const [cookieConsentAvailable, setCookieConsentAvailability] = useState<boolean>(false);

  useEffect(() => {
    checkCookieConsent()
  }, [])

  const checkCookieConsent = () => {
    const consent = localStorage.getItem(COOKIE_CONSENT_KEY);
    let result = false
    if (consent && JSON.parse(consent)) {
      result = true
    }
    setCookieConsentAvailability(result)
    return result
  }

  const setCookieConsent = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, 'true');
    setCookieConsentAvailability(true)
  }

  return {
    setCookieConsent,
    cookieConsentAvailable
  }
};
